import axios from 'axios';

export const registerSharedTrace = (deviceToken, filter) => {
	return async (dispatch) => {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		return await axios.post(`/sharedRoute/device/${deviceToken}/trace/from/${filter.dateFrom.replace('T', ' ') + ':00'}/to/${filter.dateTo.replace('T', ' ') + ':00'}`)
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({
						type: 'ADD_SHARED_TRACE_TOKEN',
						data: { token: response.data.data.token }
					});
				}
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			})
			.catch((err) => {
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie tworzenia linku udostępnienia.", type: 1 } }));
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			});
	}
}

export const getSharedTrace = (token) => {
	return async (dispatch) => {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		return await axios.get(`/shared-trace/${token}/get`)
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'SET_SHARED_DEVICE', data: { device: response.data.data } });
				}
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			})
			.catch(() => {
				console.error('Niepowodzenie ustawiania dat trasy.');
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			});
	}
}
