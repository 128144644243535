
const initState = {
      groups: [],
      ownedGroups: [],
};

const GroupReducer = (state = initState, action) => {
	switch (action.type) {
		case 'SET_GROUPS':
			return {
				...state,
				groups: action.data.groups
			};
            case 'ADD_GROUP':
                  return {
                        ...state,
                        groups: [
                              ...state.groups,
                              action.data.group
                        ]
                  };
            case 'SET_OWNED_GROUPS':
                  return {
                        ...state,
                        ownedGroups: action.data.groups
                  };
            case 'ADD_OWNED_GROUP':
                  return {
                        ...state,
                        ownedGroups: [
                              ...state.ownedGroups,
                              action.data.group
                        ]
                  };
		default:
			return state;
	}
};

export default GroupReducer;
