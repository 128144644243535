const LocatorSettings = ({ settings, handleChange }) => {
      if (!settings) return (<></>);

      return (
            <>
                  <div className="row">
                        <div className="col-12 col-md-3">
                              <div className="form-floating mb-4">
                                    <input
                                          type="number"
                                          id="distanceTrigger"
                                          className="form-control"
                                          defaultValue={settings.distanceTrigger}
                                          placeholder=" "
                                          max="7"
                                          min="0"
                                    />
                                    <label className="form-label" htmlFor="distanceTrigger">Promień wyzwalania GPS (m)</label>
                              </div>
                        </div>
                        <div className="col-12 col-md-3">
                              <div className="form-floating mb-4">
                                    <input
                                          type="number"
                                          id="gpsDelayS"
                                          className="form-control"
                                          defaultValue={settings.gpsDelayS}
                                          placeholder=" "
                                          min="5"
                                    />
                                    <label className="form-label" htmlFor="gpsDelayS">Odstęp pobierania lokalizacji (s)</label>
                              </div>
                        </div>
                        <div className="col-12 col-md-3">
                              <div className="form-floating mb-4">
                                    <input
                                          type="number"
                                          id="triggerLevel"
                                          className="form-control"
                                          defaultValue={settings.triggerLevel}
                                          placeholder=" "
                                          min="5"
                                          max="100"
                                    />
                                    <label className="form-label" htmlFor="triggerLevel">Poziom wyzwalania akcelerometru</label>
                              </div>
                        </div>
                        <div className="col-12 col-md-6">
                              <div className="form-floating mb-4">
                                    <input
                                          type="number"
                                          id="triggerDurationS"
                                          className="form-control"
                                          defaultValue={settings.triggerDurationS}
                                          placeholder=" "
                                          min={settings.gpsDelayS}
                                    />
                                    <label className="form-label" htmlFor="triggerDurationS">Jak długo pobierać informację GPS po wykryciu ruchu</label>
                              </div>
                        </div>
                        <div className="col-12 col-md-6">
                              <div className="form-floating mb-4">
                                    <select
                                          type="number"
                                          id="mode"
                                          className="form-control"
                                          defaultValue={settings.mode}
                                          placeholder=" " >
                                          <option value="AUTO">Automatyczny</option>
                                          <option value="LTE_ONLY">Tylko LTE</option>
                                          <option value="WIFI_ONLY">Tylko WIFI</option>
                                    </select>
                                    <label className="form-label" htmlFor="mode">Jak łączyć z siecią?</label>
                              </div>
                        </div>
                        <div className="col-12 col-md-6">
                              <div className="form-floating mb-4">
                                    <input
                                          type="number"
                                          id="maxLogsPerReqLTE"
                                          className="form-control"
                                          defaultValue={settings.maxLogsPerReqLTE}
                                          placeholder=" "
                                          min="1"
                                          max="7"
                                    />
                                    <label className="form-label" htmlFor="maxLogsPerReqLTE">Maksymalna liczba logów dla łącza LTE</label>
                              </div>
                        </div>
                        <div className="col-12 col-md-6">
                              <div className="form-floating mb-4">
                                    <input
                                          type="number"
                                          id="maxLogsPerReqWIFI"
                                          className="form-control"
                                          defaultValue={settings.maxLogsPerReqWIFI}
                                          placeholder=" "
                                          min="1"
                                          max="50"
                                          />
                                    <label className="form-label" htmlFor="maxLogsPerReqWIFI">Maksymalna liczba logów dla łącza WIFI</label>
                              </div>
                        </div>
                  </div>
            </>
      );
}

export default LocatorSettings;
