import { memo, useCallback, useState } from 'react'
import { GoogleMap, PolylineF, useJsApiLoader } from '@react-google-maps/api';
import MarkerLabeled from './MarkerLabeled/MarkerLabeled';
import MarkerClusterLabeled from './MarkerClusterLabeled/MarkerClusterLabeled';

const GoogleMapContainer = ({ containerStyle, device }) => {
      const zoom = 10;
      const defaultContainerStyle = {
            width: '400px',
            height: '400px'
      };
      const [map, setMap] = useState(null);
      const currentLatLng = {
            lat: parseFloat((device && device.currentPosition) ? device.currentPosition.width : 0),
            lng: parseFloat((device && device.currentPosition) ? device.currentPosition.hight : 0)
      };
      const { isLoaded } = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: "AIzaSyBmz7S0wvB1q3lvcEY6WTlrSkVO2VZumXM"
      });
      const waypoints = (device && device.trace && device.trace.length) ? device.trace.map(p => ({
            lat: p.width,
            lng: p.hight
      })) : null;

      const onLoad = useCallback(function callback(map) {
            setMap(map);
      }, []);

      const onUnmount = useCallback(function callback() {
            setMap(null);
      }, []);

      return (
            isLoaded ? (
                  <GoogleMap
                        mapContainerStyle={containerStyle || defaultContainerStyle}
                        center={currentLatLng}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{ streetViewControl: false }}
                  >
                        {waypoints ? <>
                              <PolylineF path={waypoints} options={{ strokeColor: "#00ADB5" }} />
                              <MarkerClusterLabeled trace={device.trace} map={map}>
                                    {(clusterer) => device.trace.map((p, i) => (
                                          <MarkerLabeled
                                                position={{ lat: parseFloat(p.width), lng: parseFloat(p.hight) }}
                                                title={device ? device.name : ''}
                                                content={
                                                      [`Data wpisu: ${p.updated}`,
                                                      `Wysokość npm.: ${p.hightNPM}`,
                                                      `Szerokość: ${p.width}`,
                                                      `Wysokość: ${p.hight}`,
                                                      `Prędkość: ${p.speed}`]
                                                }
                                                key={i}
                                                clusterer={clusterer}
                                                zIndex={110}
                                          />))
                                    }
                              </MarkerClusterLabeled>
                        </> : <></>}
                        <MarkerLabeled
                              position={currentLatLng}
                              title={device ? device.name + ' (actual)' : ''}
                              content={device && device.currentPosition ?
                                    [`Data wpisu: ${device.currentPosition.updated}`,
                                    `Wysokość npm.: ${device.currentPosition.hightNPM}`,
                                    `Szerokość: ${device.currentPosition.width}`,
                                    `Wysokość: ${device.currentPosition.hight}`,
                                    `Prędkość: ${device.currentPosition.speed}`] : ''}
                              zIndex={111}
                        />
                  </GoogleMap>
            ) : <></>
      );
}

export default memo(GoogleMapContainer);
