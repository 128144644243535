import axios from 'axios';

export const registerUser = (userData) => {
	return async (dispatch) => {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		return await axios.post('/register', userData)
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'REGISTER_USER_SUCCESS' });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Pomyślnie utworzono konto użytkownika.", type: 0 } }));
				} else {
					dispatch({ type: 'REGISTER_USER_ERROR' });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Nie udało się utworzyć konta użytkownika.", type: 1 } }));
				}

				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			})
			.catch(() => {
				dispatch({ type: 'REGISTER_USER_ERROR' });
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Nie udało się utworzyć konta użytkownika.", type: 1 } }));
			});
	}
}

export const login = (credentials) => {
	return async (dispatch, getState) => {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		let response = await axios.post('/login', credentials)
			.catch((err) => {
				dispatch({ type: 'LOGIN_ERROR', err });
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Nie udało się zalogować do konta użytkownika.", type: 1 } }));
			});

		return attemptLogin({ dispatch, getState }, response.data);
	}
}

export const attemptLogin = async ({ dispatch, getState }, { token }) => {
	if (token) {
		dispatch({ type: 'LOGIN_SET_TOKEN', token: token });
	}

	if (!getState().auth.token) {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
		return;
	}

	let { data } = await axios.get('/profile')
		.catch((err) => {
			dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			dispatch({ type: 'LOGIN_ERROR', err });
			document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Nie udało się zalogować do konta użytkownika.", type: 1 } }));
		});

	if (data.user) {
		data.user = JSON.parse(data.user);
	}

	dispatch({ type: 'LOGIN_SET_USER', ...data });
	dispatch({ type: 'LOGIN_SUCCESS' });
	dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
	document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Pomyślnie zalogowano do konta użytkownika.", type: 0 } }));
}

export const logout = () => {
	return (dispatch) => {
		dispatch({ type: 'LOGOUT' });
		document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Pomyślnie wylogowano z konta użytkownika.", type: 0 } }));
	}
}

export const passwordChange = (email) => {
	return async (dispatch) => {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		return await axios.post(`/password-change/${email}`)
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'PASSWORD_CHANGE_SUCCESS' });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: response.data.message, type: 0 } }));
				} else {
					dispatch({ type: 'PASSWORD_CHANGE_ERROR' });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: response.data.message, type: 1 } }));
				}

				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			})
			.catch(() => {
				dispatch({ type: 'PASSWORD_CHANGE_ERROR' });
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: `Nie udało się wysłać maila z przypomnieniem hasła do ${email}.`, type: 1 } }));
			});
	}
}

export const passwordReset = (password, token) => {
	return async (dispatch) => {
		if (!token) {
			document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Brak tokenu resetu hasła.", type: 1 } }));
			return;
		}

		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		return await axios.post(`/password-reset/${token}`, { 'password': password })
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'PASSWORD_RESET_SUCCESS' });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: response.data.message, type: 0 } }));
				} else {
					dispatch({ type: 'PASSWORD_RESET_ERROR' });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: response.data.message, type: 1 } }));
				}

				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			})
			.catch(() => {
				dispatch({ type: 'PASSWORD_RESET_ERROR' });
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: `Nie udało się zmienić hasła.`, type: 1 } }));
			});
	}
}
