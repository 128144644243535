import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { passwordReset } from '../../Store/Actions/authActions';

const PasswordReset = ({ auth, passwordReset, resetPasswordReset, setResetPasswordToken }) => {
	const defaultCredentials = {
		password: "",
		passwordRepeat: "",
		notSamePassword: false
	};
	const [credentials, setCredentials] = useState(defaultCredentials);
	const params = useParams();

	if (auth.passwordResetSuccess) {
		resetPasswordReset();
		return <Navigate to="/login" />
	}

	if (params.token && !auth.passwordResetToken) {
		setResetPasswordToken(params.token);
		window.history.replaceState([], '', '/password-reset');
	}

	const handleChange = (e) => {
		setCredentials({ ...credentials, [e.target.id]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (credentials.password !== credentials.passwordRepeat) {
			setCredentials({ ...credentials, notSamePassword: true });
			return;
		}

		setCredentials({ ...credentials, notSamePassword: false });
		passwordReset(credentials.password, auth.passwordResetToken);
	};

	return (
		<section className="vh-100">
			<div className="container py-5 h-100">
				<div className="row d-flex align-items-center justify-content-center h-100">
					<div className="col-md-8 col-lg-7 col-xl-6">
						<img
							src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
							className="img-fluid"
							alt="Phone"
						/>
					</div>
					<div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
						<form onSubmit={handleSubmit}>
							<h3 className='h3 mb-4'>Reset hasła</h3>
							<div className='form-floating mb-4'>
								<input
									type="password"
									id="password"
									className={`form-control form-control-lg${credentials.notSamePassword ? " is-invalid" : ""}`}
									placeholder=' '
									onChange={handleChange}
									required
								/>
								<label className="form-label" htmlFor='password'>Hasło</label>
							</div>
							<div className='form-floating mb-4'>
								<input
									type="password"
									id="passwordRepeat"
									className={`form-control form-control-lg${credentials.notSamePassword ? " is-invalid" : ""}`}
									placeholder=' '
									onChange={handleChange}
									required
								/>
								<label className="form-label" htmlFor='passwordRepeat'>Powtórz hasło</label>
								<div className="invalid-feedback">
									Hasła się różnią!
								</div>
							</div>
							<button type="submit" className="btn btn-primary btn-lg btn-block">
								Zmień hasło <i className="bi bi-arrow-right"></i>
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		passwordReset: (password, token) => dispatch(passwordReset(password, token)),
		resetPasswordReset: () => dispatch({ type: "PASSWORD_RESET_CLEAR" }),
		setResetPasswordToken: (token) => dispatch({ type: "SET_RESET_PASSWORD_TOKEN", token })
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
