import React, { useEffect, useState } from 'react';
import { Navigate, NavLink, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser } from '../../Store/Actions/authActions';

const Register = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [userData, setUserData] = useState({
		name: '',
		surname: '',
		email: '',
		password: '',
		group: null
	});

	useEffect(() => {
		if (searchParams.size && searchParams.has('email') && searchParams.has('group')) {
			setUserData({
				...userData,
				email: searchParams.has('email') && !userData.email.length ? searchParams.get('email') : userData.email,
				group: searchParams.has('group') ? parseInt(searchParams.get('group')) : userData.group
			});

			setSearchParams(new URLSearchParams());
		}

	}, []);

	if (props.auth.registerSuccess) {
		props.auth.registerSuccess = null;
		return <Navigate to="/login" />
	}

	if (props.auth.user && props.auth.user.email.length) {
		return <Navigate to="/user-panel" />
	}

	const handleChange = (e) => {
		setUserData({
			...userData,
			[e.target.id]: e.target.value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		props.registerUser(userData);
	};

	return (
		<section className="vh-100">
			<div className="container py-5 h-100">
				<div className="row d-flex align-items-center justify-content-center h-100">
					<div className="col-md-8 col-lg-7 col-xl-6">
						<img
							src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
							className="img-fluid"
							alt="Phone"
						/>
					</div>
					<div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
						<form onSubmit={handleSubmit}>
							<h3 className='h3 mb-4'>Rejestracja</h3>
							<div className='form-floating mb-4'>
								<input type="text" id="name" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required />
								<label className="form-label" htmlFor='name'>Imię</label>
							</div>
							<div className='form-floating mb-4'>
								<input type="text" id="surname" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required />
								<label className="form-label" htmlFor='surname'>Nazwisko</label>
							</div>
							<div className='form-floating mb-4'>
								<input type="email" id="email" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required value={userData.email} />
								<label className="form-label" htmlFor='email'>E-mail</label>
							</div>
							<div className='form-floating mb-4'>
								<input type="password" id="password" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required />
								<label className="form-label" htmlFor='password'>Hasło</label>
							</div>
							<div className="d-flex justify-content-around align-items-center mb-4">
								<NavLink to="/login" className="text-decoration-none">Chcesz się zalogować?</NavLink>
							</div>
							<button type="submit" className="btn btn-primary btn-lg btn-block">
								Zarejestruj <i className="bi bi-arrow-right"></i>
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		registerUser: (userData) => dispatch(registerUser(userData))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
