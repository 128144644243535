import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import Logo from "../../Components/Logo/Logo";
import GoogleMapContainer from "../../Components/GoogleMapContainer/GoogleMapContainer";
import { getSharedTrace } from "../../Store/Actions/sharedRouteAction";

const SharedTrace = ({ device, getSharedTrace }) => {
      const { slug } = useParams();

      useEffect(() => {
            getSharedTrace(slug);

            return () => {

            }
      }, [])

      return (
            <div className='container-fluid'>
                  <div className="row flex-nowrap">
                        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
                              <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                                    <Logo />
                              </div>
                        </div>
                        <div className="col">
                              <section className="vh-100">
                                    <div className="container h-100">
                                          <div className="row h-25">
                                                <div className="col-md-12">
                                                      <h5 className='h5 my-2'>Udostępniona trasa urządzenia {device.name} z okresu {device.from} - {device.to}</h5>
                                                </div>
                                          </div>
                                          <div className="row d-flex align-items-center justify-content-center h-75">
                                                <div className="col-md-12 h-100 px-0">
                                                      <GoogleMapContainer
                                                            containerStyle={{ width: "100%", height: "100%" }}
                                                            device={device}
                                                      />
                                                </div>
                                          </div>
                                    </div>
                              </section>
                        </div>
                  </div>
            </div>
      );
}
const mapStateToProps = (state) => {
      return {
            device: state.frontFeatures.sharedDevice
      };
};
const mapDispatchToProps = (dispatch) => {
      return {
            getSharedTrace: (token) => dispatch(getSharedTrace(token))
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedTrace);
