import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import SettingsType from "./SettingsType/SettingsType";
import { useState } from 'react';
import { changeDeviceName } from "../../../../Store/Actions/deviceActions";

const Settings = ({ devices, changeDeviceName }) => {
      const { slug } = useParams();
      const device = devices[slug];
      const [isEditingName, setIsEditingName] = useState(false);

      if (!device) {
            return (<></>);
      }

      const handleEditClick = () => {
            setIsEditingName(!isEditingName);
      };

      const handleEditNameSubmit = (e) => {
            e.preventDefault();
            changeDeviceName(device.token, e.currentTarget.deviceName.value);
            setIsEditingName(false);
      };

      return (
            <>
                  <div className="container my-5">
                        <div className="row">
                              <div className="col-12">
                                    {isEditingName ?
                                          <form className="container" onSubmit={handleEditNameSubmit}>
                                                <div className="row">
                                                      <div className="col-auto">
                                                            <div className="form-floating mb-4">
                                                                  <input type="text" id="deviceName" className="form-control" placeholder=" " />
                                                                  <label className="form-label" htmlFor="deviceName">Nowa nazwa urządzenia</label>
                                                            </div>
                                                      </div>
                                                      <div className="col-auto">
                                                            <button type="submit" className="btn btn-primary">Zapisz <i className="bi bi-save"></i></button>
                                                            <button type="button" className="btn btn-primary" onClick={handleEditClick}>Anuluj <i className="bi bi-x"></i></button>
                                                      </div>
                                                </div>
                                          </form>
                                          :
                                          <h2>{device.name} <i className="bi bi-pencil-square" style={{ cursor: 'pointer' }} onClick={handleEditClick}></i></h2>
                                    }
                              </div>
                        </div>
                  </div>
                  <SettingsType device={device} />
            </>
      );
};

const mapStateToProps = (state) => {
      return {
            devices: state.device.devices
      };
};

const mapDispatchToProps = (dispatch) => {
      return {
            changeDeviceName: (deviceToken, name) => dispatch(changeDeviceName(deviceToken, name))
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
