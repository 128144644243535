import { connect } from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import { useEffect, useRef, useState } from 'react';
import { createNewGroup, getOwnedGroups } from '../../../Store/Actions/GroupActions';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import GroupSettings from './GroupSettings/GroupSettings';

const Group = ({ ownedGroups, ownedDevices, getOwnedGroups, createNewGroup }) => {
      const defaultState = '';
      const [groupName, setGroupName] = useState(defaultState);
      const modalRef = useRef(null);

      useEffect(() => {
            if (!ownedGroups.length) {
                  getOwnedGroups();
            }

            return () => { }
      }, []);

      const handleAddGroup = (e) => {
            e.preventDefault();
            if (groupName !== '') {
                  let data = {
                        name: groupName
                  };

                  bootstrap.Modal.getInstance(modalRef.current).hide();
                  createNewGroup(data);
                  setGroupName(defaultState);
            }
      };

      const handleAddGroupOnChange = (e) => {
            e.preventDefault();
            setGroupName(e.target.value);
      }

      return (
            <div className='container-fluid h-100 pt-2'>
                  <div className="row flex-nowrap mb-5">
                        <div className="col-12 col-md-4">
                              <Modal
                                    actionButtonText={`Utwórz grupę`}
                                    title={`Utwórz grupę`}
                                    id={`addGroupModal`}
                                    onSubmit={handleAddGroup}
                                    refModal={modalRef}
                              >
                                    <div className='form-floating mb-3'>
                                          <input
                                                type='text'
                                                className='form-control form-control-lg'
                                                id='newGroupName'
                                                name='newGroupName'
                                                aria-describedby='newGroupNameHelp'
                                                placeholder=' '
                                                onChange={handleAddGroupOnChange}
                                                value={groupName}
                                                required
                                          />
                                          <label htmlFor='newGroupName' className='form-label'>Nazwa grupy</label>
                                          <div id='newGroupNameHelp' className='form-text'>Wprowadź nazwę grupy</div>
                                    </div>
                              </Modal>
                        </div>
                  </div>
                  {!ownedGroups.length ? <></>
                        :
                        <div className='card h-75'>
                              <div className='card-header'>
                                    <h5 className='card-title'>Ustawienia grup</h5>
                              </div>
                              <div className='card-body overflow-auto'>
                                    {ownedGroups.map((group, index) => {
                                          let identifier = `settings${index}`;
                                          return (
                                                <GroupSettings
                                                      key={identifier}
                                                      identifier={identifier}
                                                      group={group}
                                                      ownedDevices={ownedDevices}
                                                />
                                          )
                                    })}
                              </div>
                        </div>
                  }
            </div>
      );
}

const mapStateToProps = (state) => {
      return {
            ownedGroups: state.groups.ownedGroups,
            ownedDevices: state.device.devices
      };
};

const mapDispatchToProps = (dispatch) => {
      return {
            createNewGroup: (data) => dispatch(createNewGroup(data)),
            getOwnedGroups: () => dispatch(getOwnedGroups())
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(Group);
