import React, { useState } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { passwordChange } from '../../Store/Actions/authActions';

const PasswordChange = ({ auth, passwordChange, resetPasswordChangeSuccess }) => {
	const [email, setEmail] = useState("");

	if (auth.passwordChangeSuccess) {
		resetPasswordChangeSuccess();
		return <Navigate to="/login" />
	}

	const handleChange = (e) => {
		setEmail(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		passwordChange(email);
	};

	return (
		<section className="vh-100">
			<div className="container py-5 h-100">
				<div className="row d-flex align-items-center justify-content-center h-100">
					<div className="col-md-8 col-lg-7 col-xl-6">
						<img
							src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
							className="img-fluid"
							alt="Phone"
						/>
					</div>
					<div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
						<form onSubmit={handleSubmit}>
							<h3 className='h3 mb-4'>Zmiana hasła</h3>
							<div className='form-floating mb-4'>
								<input type="email" id="email" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required />
								<label className="form-label" htmlFor='email'>E-mail</label>
							</div>
							<div className="d-flex justify-content-around align-items-center mb-4">
								<NavLink to="/login" className="text-decoration-none">Chcesz się zalogować?</NavLink>
							</div>
							<button type="submit" className="btn btn-primary btn-lg btn-block">
								Wyślij żądanie zmiany hasła <i className="bi bi-arrow-right"></i>
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		passwordChange: (userData) => dispatch(passwordChange(userData)),
		resetPasswordChangeSuccess: () => dispatch({ type: 'PASSWORD_CHANGE_CLEAR' })
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
