import Portal from "../Portal/Portal";

const Modal = ({
      children,
      actionButtonText,
      actionButtonBTNColor = 'primary',
      actionButtonPrettier = true,
      title,
      id,
      onSubmit,
      refModal,
      yesNo = false,
      portalForm = false
}) => {
      if (!id) {
            return <></>;
      }

      return (
            <>
                  <button type="button" className={`btn btn-${actionButtonBTNColor} ${actionButtonPrettier ? "btn-lg btn-block h-100" : ""}`} data-bs-toggle="modal" data-bs-target={`#${id}`}>
                        {actionButtonText}
                  </button >

                  {portalForm && (<Portal target={document.querySelector('body')}>
                        <div className="modal fade" id={id} tabIndex="-1" ref={refModal} aria-labelledby={`${id}Label`} aria-hidden="true">
                              <div className="modal-dialog">
                                    <form className="modal-content" onSubmit={onSubmit}>
                                          <div className="modal-header">
                                                <h5 className="modal-title" id={`${id}Label`}>{title}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                          <div className="modal-body">
                                                {children}
                                          </div>
                                          <div className="modal-footer">
                                                <button type="submit" className="btn btn-primary">{(yesNo ? "Tak" : "Zapisz")}</button>
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{(yesNo ? "Nie" : "Zamknij")}</button>
                                          </div>
                                    </form>
                              </div>
                        </div>
                  </Portal>)}

                  {!portalForm && (
                  <div className="modal fade" id={id} tabIndex="-1" ref={refModal} aria-labelledby={`${id}Label`} aria-hidden="true">
                        <div className="modal-dialog">
                              <form className="modal-content" onSubmit={onSubmit}>
                                    <div className="modal-header">
                                          <h5 className="modal-title" id={`${id}Label`}>{title}</h5>
                                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                          {children}
                                    </div>
                                    <div className="modal-footer">
                                          <button type="submit" className="btn btn-primary">{(yesNo ? "Tak" : "Zapisz")}</button>
                                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{(yesNo ? "Nie" : "Zamknij")}</button>
                                    </div>
                              </form>
                        </div>
                  </div>
                  )}
            </>
      );
};

export default Modal;
