import { authSubscribe, authUserSubscriber } from "../Subscribers/authSubscriber";

const initState = {
	token: null,
	user: {
		email: '',
		name: '',
		surname: ''
	},
	loginSuccess: null,
	logoutSuccess: null,
	registerSuccess: null,
	passwordChangeSuccess: null,
	passwordResetSuccess: null,
	passwordResetToken: null
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'REGISTER_USER_SUCCESS':
			return {
				...state,
				registerSuccess: true
			};
		case 'REGISTER_USER_ERROR':
			return {
				...state,
				registerSuccess: false
			};
		case 'LOGIN_SUCCESS':
			return {
				...state,
				loginSuccess: true
			};
		case 'LOGIN_ERROR':
			authSubscribe(action);
			authUserSubscriber(action);
			return {
				...state,
				token: null,
				user: null,
				loginSuccess: false
			};
		case 'LOGIN_SET_USER':
			if (action.user) {
				authUserSubscriber(action);
			}
			return {
				...state,
				user: action.user
			};
		case 'LOGIN_SET_TOKEN':
			if (action.token) {
				authSubscribe(action);
			}
			return {
				...state,
				token: action.token
			};
		case 'LOGOUT':
			authSubscribe(action);
			authUserSubscriber(action);
			return {
				...initState,
				logoutSuccess: true
			};
		case 'PASSWORD_CHANGE_SUCCESS':
			return {
				...state,
				passwordChangeSuccess: true
			};
		case 'PASSWORD_CHANGE_ERROR':
			return {
				...state,
				passwordChangeSuccess: false
			};
		case 'PASSWORD_CHANGE_CLEAR':
			return {
				...state,
				passwordChangeSuccess: null
			};
		case 'PASSWORD_RESET_SUCCESS':
			return {
				...state,
				passwordResetSuccess: true
			};
		case 'PASSWORD_RESET_ERROR':
			return {
				...state,
				passwordResetSuccess: false
			};
		case 'PASSWORD_RESET_CLEAR':
			return {
				...state,
				passwordResetSuccess: null,
				passwordResetToken: null
			};
		case 'SET_RESET_PASSWORD_TOKEN':
			return {
				...state,
				passwordResetToken: action.token
			};
		default:
			return state;
	}
};

export default authReducer;
