
const initState = {
	devices: {},
	groupDevices: {}
};

const deviceReducer = (state = initState, action) => {
	let devices = {};
	switch (action.type) {
		case 'SET_DEVICES':
			return {
				...state,
				devices: action.data.devices
			};
		case 'SET_GROUP_DEVICES':
			return {
				...state,
				groupDevices: action.data.devices
			};
		case 'ADD_DEVICE':
			let device = action.data.device;
			return {
				...state,
				devices: { ...state.devices, [device.token]: device }
			};
		case 'SET_DEVICE_CURRENT_POSITION':
			devices = { ...state.devices };
			if (devices && Object.keys(devices).length && devices[action.data.token]) {
				return {
					...state,
					devices: {
						...state.devices,
						[action.data.token]: {
							...state.devices[action.data.token],
							currentPosition: action.data.position
						}
					}
				};
			}

			return state;
		case 'SET_GROUP_DEVICE_CURRENT_POSITION':
			devices = { ...state.groupDevices };
			if (devices && Object.keys(devices).length && devices[action.data.token]) {
				return {
					...state,
					groupDevices: {
						...state.groupDevices,
						[action.data.token]: {
							...state.groupDevices[action.data.token],
							currentPosition: action.data.position
						}
					}
				};
			}

			return state;
		case 'SET_DEVICE_TRACE':
			return {
				...state,
				devices: {
					...state.devices,
					[action.data.token]: {
						...state.devices[action.data.token],
						trace: action.data.trace
					}
				}
			};
		case 'SET_GROUP_DEVICE_TRACE':
			return {
				...state,
				groupDevices: {
					...state.groupDevices,
					[action.data.token]: {
						...state.groupDevices[action.data.token],
						trace: action.data.trace
					}
				}
			};
		case 'RESET_DEVICE_TRACE':
			return {
				...state,
				devices: {
					...state.devices,
					[action.data.token]: {
						...state.devices[action.data.token],
						trace: []
					}
				}
			};
		case 'RESET_GROUP_DEVICE_TRACE':
			return {
				...state,
				groupDevices: {
					...state.groupDevices,
					[action.data.token]: {
						...state.groupDevices[action.data.token],
						trace: []
					}
				}
			};
		case 'CHANGE_DEVICE_NAME':
			return {
				...state,
				devices: {
					...state.devices,
					[action.data.token]: {
						...state.devices[action.data.token],
						name: action.data.name
					}
				}
			};
		case 'CHANGE_DEVICE_SETTINGS':
			return {
				...state,
				devices: {
					...state.devices,
					[action.data.token]: {
						...state.devices[action.data.token],
						settings: action.data.settings
					}
				}
			};
		default:
			return state;
	}
};

export default deviceReducer;
