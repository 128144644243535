import React, { useState } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../Store/Actions/authActions';

const Login = (props) => {
	const [credentials, setCredentials] = useState({
		email: "",
		password: ""
	});

	if (props.auth.user && props.auth.user.email.length) {
            return <Navigate to="/user-panel" />
      }

	const handleChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.id]: e.target.value
		});
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		props.login(credentials);
	}

	return (
		<section className="vh-100">
			<div className="container py-5 h-100">
				<div className="row d-flex align-items-center justify-content-center h-100">
					<div className="col-md-8 col-lg-7 col-xl-6">
						<img
							src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
							className="img-fluid"
							alt="Phone"
						/>
					</div>
					<div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
						<form onSubmit={handleSubmit}>
							<h3 className='h3 mb-4'>Logowanie</h3>
							<div className='form-floating mb-4'>
								<input type="email" id="email" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required />
								<label className="form-label" htmlFor='email'>E-mail</label>
							</div>
							<div className='form-floating mb-4'>
								<input type="password" id="password" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required />
								<label className="form-label" htmlFor='password'>Hasło</label>
							</div>
							<div className="d-flex justify-content-around align-items-center mb-4">
								<NavLink to="/password-change" className="text-decoration-none">Zapomniałem hasła</NavLink>
							</div>
							<div className="d-flex justify-content-around align-items-center mb-4">
								<NavLink to="/register" className="text-decoration-none">Nie posiadasz konta?</NavLink>
							</div>
							<button type="submit" className="btn btn-primary btn-lg btn-block">
								Zaloguj <i className="bi bi-arrow-right"></i>
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (credentials) => dispatch(login(credentials))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
