import axios from 'axios';

export const getDeviceList = () => {
	return async (dispatch) => {
		return await axios.get(`/user/device/list`)
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'SET_DEVICES', data: { devices: response.data.data } });
				}
			})
			.catch(() => {
				console.error('Niepowodzenie ustawiania listy urządzeń użytkownika.');
			});
	}
}

export const registerUserDevice = (deviceToken) => {
	return async (dispatch) => {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		return await axios.post(`/device/${deviceToken}/add`)
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'ADD_DEVICE', data: { device: response.data.data } });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Pomyślnie dodano urządzenie.", type: 0 } }));
				}
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			})
			.catch((err) => {
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie rejestracji urządzenia użytkownika.", type: 1 } }));
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			});
	}
}

export const getDevicePosition = (deviceToken, isGroupDevice = false) => {
	return async (dispatch, getState) => {
		return await axios.get(`/route/device/${deviceToken}/actual`)
			.then((response) => {
				if (response.data && response.data.success) {

					let actual = isGroupDevice ? getState().device.groupDevices[deviceToken].currentPosition
						: getState().device.devices[deviceToken].currentPosition;
					if (Object.keys(actual).length && (actual.hight === response.data.data.hight && actual.width === response.data.data.width)) {
						return;
					}

					dispatch({
						type: isGroupDevice ? 'SET_GROUP_DEVICE_CURRENT_POSITION' : 'SET_DEVICE_CURRENT_POSITION',
						data: { position: response.data.data, token: deviceToken }
					});
				}
			})
			.catch(() => {
				console.error(`Niepowodzenie pobrania danych urządzenia ${deviceToken}.`);
			});
	}
}

export const getDeviceTrace = (deviceToken, filter, isGroupDevice = false) => {
	return async (dispatch) => {
		dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
		return await axios.get(`/route/device/${deviceToken}/trace/from/${filter.dateFrom.replace('T', ' ') + ':00'}/to/${filter.dateTo.replace('T', ' ') + ':00'}`)
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({
						type: isGroupDevice ? 'SET_GROUP_DEVICE_TRACE' : 'SET_DEVICE_TRACE',
						data: { trace: response.data.data, token: deviceToken }
					});
				}
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
			})
			.catch(() => {
				console.error(`Niepowodzenie pobrania danych trasy urządzenia ${deviceToken}.`);
				dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie pobrania danych trasy urządzenia.", type: 1 } }));
			});
	}
}

export const resetDeviceTrace = (deviceToken, isGroupDevice = false) => {
	return (dispatch) => {
		dispatch({ type: isGroupDevice ? 'RESET_GROUP_DEVICE_TRACE' : 'RESET_DEVICE_TRACE', data: { token: deviceToken } });
	}
}

export const changeDeviceName = (deviceToken, name) => {
	if (!name || name.length === 0 || typeof name !== 'string') {
		document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Nazwa urządzenia nie może być pusta.", type: 1 } }));
		return;
	}

	return async (dispatch) => {
		return await axios.post(`/device/${deviceToken}/name`, JSON.stringify({ name }))
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'CHANGE_DEVICE_NAME', data: { name, token: deviceToken } });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Pomyślnie zmieniono nazwę urządzenia.", type: 0 } }));
				}
			})
			.catch(() => {
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie zmiany nazwy urządzenia.", type: 1 } }));
			});
	}
}

export const changeDeviceSettings = (deviceToken, settings) => {
	if (!settings || Object.keys(settings).length === 0) {
		document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Ustawienia urządzenia nie mogą być puste.", type: 1 } }));
		return;
	}

	return async (dispatch) => {
		return await axios.post(`/device/${deviceToken}/settings`, JSON.stringify(settings))
			.then((response) => {
				if (response.data && response.data.success) {
					dispatch({ type: 'CHANGE_DEVICE_SETTINGS', data: { settings, token: deviceToken } });
					document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Pomyślnie zmieniono ustawienia urządzenia.", type: 0 } }));
				}
			})
			.catch(() => {
				document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie zmiany ustawień urządzenia.", type: 1 } }));
			});
	}
}
