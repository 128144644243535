import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import { store } from './Store/store';
import { attemptLogin } from './Store/Actions/authActions';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}api/v1/`;
const root = ReactDOM.createRoot(document.getElementById('root'));
let userToken = localStorage.getItem('token');

const renderRoot = (root) => {
	root.render(
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	);
};

if (userToken && userToken.length) {
	attemptLogin({ dispatch: store.dispatch, getState: store.getState }, {token: userToken})
		.then(renderRoot(root));
} else {
	renderRoot(root);
}
