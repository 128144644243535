import LoaderStyle from './Loader.module.css';

const Loader = ({ open = false }) => {
      return (
            <>
                  <div className={`${LoaderStyle.container}${open ? ' ' + LoaderStyle.opened : ''}`}>
                        <div className={LoaderStyle.wrapper}>
                              <div className={LoaderStyle.loader} />
                        </div>
                  </div>
            </>
      );
}

export default Loader;
