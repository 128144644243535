import NavItem from "./NavItem";

const Nav = ({ deviceList, groupDeviceList }) => {
      const liClass = (device = false) => {
            return "nav-item" + (device ? " d-flex" : "");
      };

      const linkClass = () => {
            return "nav-link p-2 d-flex align-items-center";
      };

      const strongClass = () => {
            return "ms-1 d-none d-sm-inline";
      };

      return (
            <ul className="nav nav-pills flex-column mb-auto align-items-center align-items-sm-start" id="menu">
                  <NavItem
                        containerElementClass={liClass()}
                        linkClass={linkClass()}
                        strongClass={strongClass()}
                        itemLabel="Dodaj urządzenie"
                        itemUrl="/user-panel/device/add"
                  />
                  {Object.keys(deviceList).length ?
                        <>
                              <NavItem
                                    containerElementClass={liClass()}
                                    linkClass={linkClass()}
                                    strongClass={strongClass()}
                                    itemLabel="Zarządzaj grupami"
                                    itemUrl="/user-panel/group"
                              />
                              {Object.values(deviceList).map((device, id) => <NavItem
                                    containerElementClass={liClass(true)}
                                    linkClass={linkClass()}
                                    strongClass={strongClass()}
                                    itemLabel={device.name}
                                    itemUrl={`/user-panel/device/${device.token}`}
                                    itemIcon="bi-phone"
                                    key={id}
                                    deviceLink={true}
                              />)}
                        </> : <></>}
                  {Object.keys(groupDeviceList).length ? Object.values(groupDeviceList).map((device, id) => <NavItem
                        containerElementClass={liClass(true)}
                        linkClass={linkClass()}
                        strongClass={strongClass()}
                        itemLabel={device.name}
                        itemUrl={`/user-panel/group/device/${device.token}`}
                        itemIcon="bi-phone"
                        key={id}
                  />) : <></>}
            </ul>
      );
};

export default Nav;
