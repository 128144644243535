import { NavLink } from "react-router-dom";

const NavItem = ({ containerElementName = 'li', containerElementClass, linkClass, strongClass, itemLabel, itemUrl, itemIcon, deviceLink = false }) => {
      const ContainerElementName = containerElementName;

      return (
            <ContainerElementName className={containerElementClass}>
                  <NavLink
                        to={itemUrl}
                        className={(linkClass ? linkClass : "")}
                  >
                        <i className={`fs-4 bi ${(itemIcon ? itemIcon : "bi-plus-circle")}`}></i> <strong className={strongClass}>{itemLabel}</strong>
                  </NavLink>
                  {deviceLink ?
                        <NavLink
                              to={itemUrl + '/settings'}
                              className={"inside-nav-link " + (linkClass ? linkClass : "")}
                        >
                              <i className={`fs-4 bi bi-gear`}></i>
                        </NavLink>
                        :
                        <></>
                  }
            </ContainerElementName>
      );
};

export default NavItem;
