import axios from 'axios';

export const authSubscribe = (action) => {
      if(action.token) {
            axios.defaults.headers.common['Authorization'] = `BEARER ${action.token}`;
            localStorage.setItem('token', action.token);
      }
      else {
            axios.defaults.headers.common['Authorization'] = null;
            localStorage.removeItem('token');
      }
};

export const authUserSubscriber = (action) => {
      if (action.user) {
            localStorage.setItem('user', action.user);
      } else {
            localStorage.removeItem('user');
      }
};
