import { NavLink } from "react-router-dom";

const Logo = () => {
    return (
        <NavLink
        to="/"
        className={() => { return "d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"; }}
        >
            <span className="fs-5 d-none d-sm-inline"><img src="/images/sledzik_signature.png" style={{width: "100%", marginLeft: '-5px'}} /></span>
        </NavLink>
    );
}

export default Logo;
