import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import { connect } from 'react-redux';
import Modal from '../../../../Components/Modal/Modal';
import { addUserToGroup, deleteGroup, removeUserFromGroup, setGroupDevices, addGroupRole, removeRole } from '../../../../Store/Actions/GroupActions';
import { useRef, useState } from 'react';

const GroupSettings = ({ identifier, group, ownedDevices, removeGroup, addToGroup, removeUser, setGroupDevices, addGroupRole, removeRole }) => {
      const [settings, setSettings] = useState({ selectedDevices: group.devices && group.devices.length ? group.devices : [] });
      const removeGroupModalRef = useRef(null);
      const addDeviceModalRef = useRef(null);
      const addMemberModalRef = useRef(null);
      const groupRolesModalRef = useRef(null);
      const removeUserModalRef = useRef([]);
      const removeRoleModalRef = useRef([]);

      const handleRemoveGroup = (e) => {
            e.preventDefault();
            bootstrap.Modal.getInstance(removeGroupModalRef.current).hide();
            removeGroup(group.id);
      }

      const handleAddDevice = (e) => {
            e.preventDefault();
            bootstrap.Modal.getInstance(addDeviceModalRef.current).hide();
            setGroupDevices(group.id, settings.selectedDevices);
      }

      const handleAddMember = (e) => {
            e.preventDefault();
            bootstrap.Modal.getInstance(addMemberModalRef.current).hide();
            addToGroup(group.id, e.currentTarget.email.value);
      };

      const handleAddGroupRole = (e) => {
            e.preventDefault();
            addGroupRole(group.id, e.currentTarget.role.value);
            e.currentTarget.role.value = '';
      };

      const handleRemoveRole = (e, roleId, index) => {
            e.preventDefault();
            e.stopPropagation();
            bootstrap.Modal.getInstance(removeRoleModalRef.current[index]).hide();
            removeRole(group.id, roleId);
            bootstrap.Modal.getInstance(groupRolesModalRef.current).show();
      }

      const handleRemoveUser = (e, email, index) => {
            e.preventDefault();
            bootstrap.Modal.getInstance(removeUserModalRef.current[index]).hide();
            removeUser(group.id, email);
      }

      const handleOnchange = (e) => {
            e.preventDefault();

            if (e.currentTarget.selectedOptions) {
                  setSettings({
                        ...settings,
                        [e.currentTarget.name]: [...e.currentTarget.selectedOptions].map(o => o.value)
                  });
            }
      }

      return (
            <div className="row flex-nowrap mb-3">
                  <div className="col-12">
                        <div className='container'>
                              <div className="row mb-1">
                                    <div className='col-8 col-md-4 col-xl-2'>
                                          <button
                                                className="btn btn-secondary"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#${identifier}`}
                                                aria-expanded="false"
                                                aria-controls={identifier}
                                          >
                                                {group.name}
                                          </button>
                                    </div>
                                    <div className='col-4 col-md-2 col-xl-1'>
                                          <Modal
                                                title={`Czy na pewno usunąć grupę "${group.name}"?`}
                                                id={`removeGroup${identifier}`}
                                                actionButtonText={<i className="bi bi-trash"></i>}
                                                actionButtonBTNColor="dark"
                                                actionButtonPrettier={false}
                                                yesNo={true}
                                                onSubmit={handleRemoveGroup}
                                                refModal={removeGroupModalRef}
                                          />
                                    </div>
                              </div>
                              <div className="row">
                                    <div className="col-12">
                                          <div className="collapse" id={identifier}>
                                                <div className="card card-body">
                                                      <div className="row">
                                                            <div className="col-12 col-md-4">
                                                                  <Modal
                                                                        title="Dodaj członka grupy"
                                                                        id={`addMember${identifier}`}
                                                                        actionButtonText="Dodaj członka"
                                                                        actionButtonPrettier={false}
                                                                        onSubmit={handleAddMember}
                                                                        refModal={addMemberModalRef}
                                                                  >
                                                                        <div className="form-floating mb-3">
                                                                              <input
                                                                                    type="email"
                                                                                    className="form-control"
                                                                                    id={`addMemberEmail${identifier}`}
                                                                                    name="email"
                                                                                    placeholder=' '
                                                                                    required
                                                                              />
                                                                              <label htmlFor={`addMemberEmail${identifier}`} className="form-label">Email</label>
                                                                        </div>
                                                                  </Modal>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-2 mt-md-0">
                                                                  <Modal
                                                                        title="Przypisane urządzenia"
                                                                        id={`addDevice${identifier}`}
                                                                        actionButtonText="Przypisane urządzenia"
                                                                        actionButtonPrettier={false}
                                                                        onSubmit={handleAddDevice}
                                                                        refModal={addDeviceModalRef}
                                                                  >
                                                                        <div className="mb-3">
                                                                              <select
                                                                                    className="form-select"
                                                                                    multiple
                                                                                    aria-label="multiple select example"
                                                                                    id={`addDeviceToken${identifier}`}
                                                                                    name="selectedDevices"
                                                                                    placeholder=' '
                                                                                    onChange={handleOnchange}
                                                                                    value={settings.selectedDevices}
                                                                              >
                                                                                    {Object.values(ownedDevices).map((device, index) => <option
                                                                                          key={index}
                                                                                          value={device.token}
                                                                                    >{device.name}</option>)}
                                                                              </select>
                                                                        </div>
                                                                  </Modal>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-2 mt-md-0">
                                                                  <Modal
                                                                        title="Role grupy"
                                                                        id={`groupRoles${identifier}`}
                                                                        actionButtonText="Role grupy"
                                                                        actionButtonPrettier={false}
                                                                        onSubmit={handleAddGroupRole}
                                                                        refModal={groupRolesModalRef}
                                                                  >
                                                                        <div className="form-floating">
                                                                              <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id={`addGroupRole${identifier}`}
                                                                                    name="role"
                                                                                    placeholder=' '
                                                                                    required
                                                                              />
                                                                              <label htmlFor={`addGroupRole${identifier}`} className="form-label">Nazwa nowej roli</label>
                                                                        </div>
                                                                        <div className='container-fluid'>
                                                                              {group.roles.map((role, index) => <div
                                                                                    className="row my-2"
                                                                                    key={index}
                                                                              >
                                                                                    <div className='col-10'>{role.name}</div>
                                                                                    <div className='col-2'>
                                                                                          <Modal
                                                                                                title={`Czy na pewno usunąć rolę "${role.name}" z grupy "${group.name}"?`}
                                                                                                id={`removeRole${identifier}${index}`}
                                                                                                actionButtonText={<i className="bi bi-trash"></i>}
                                                                                                actionButtonBTNColor="dark"
                                                                                                actionButtonPrettier={false}
                                                                                                yesNo={true}
                                                                                                onSubmit={(e) => handleRemoveRole(e, role.id, index)}
                                                                                                refModal={(modal) => removeRoleModalRef.current[index] = modal}
                                                                                                portalForm={true}
                                                                                          />
                                                                                    </div>
                                                                              </div>)}
                                                                        </div>
                                                                  </Modal>
                                                            </div>
                                                      </div>
                                                      <div className="row">
                                                            {group.users && group.users.length ?
                                                                  <div className="col-12">
                                                                        <div className="table-responsive">
                                                                              <table className="table table-striped table-hover">
                                                                                    <thead>
                                                                                          <tr>
                                                                                                <th scope="col">Email</th>
                                                                                                <th scope="col">Imię</th>
                                                                                                <th scope="col">Nazwisko</th>
                                                                                                <th scope="col">Usuń</th>
                                                                                          </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                          {group.users.map((user, index) => {
                                                                                                return (
                                                                                                      <tr key={index}>
                                                                                                            <td>{user.email}</td>
                                                                                                            <td>{user.name}</td>
                                                                                                            <td>{user.surname}</td>
                                                                                                            <td>
                                                                                                                  <Modal
                                                                                                                        title={`Czy na pewno usunąć użytkownika "${user.email}" z grupy "${group.name}"?`}
                                                                                                                        id={`removeUser${identifier}${index}`}
                                                                                                                        actionButtonText={<i className="bi bi-trash"></i>}
                                                                                                                        actionButtonBTNColor="dark"
                                                                                                                        actionButtonPrettier={false}
                                                                                                                        yesNo={true}
                                                                                                                        onSubmit={(e) => handleRemoveUser(e, user.email, index)}
                                                                                                                        refModal={(modal) => removeUserModalRef.current[index] = modal}
                                                                                                                  />
                                                                                                            </td>
                                                                                                      </tr>
                                                                                                )
                                                                                          })}
                                                                                    </tbody>
                                                                              </table>
                                                                        </div>
                                                                  </div>
                                                                  :
                                                                  <></>}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      );
}

const mapStateToProps = () => {
      return {};
};

const mapDispatchToProps = (dispatch) => {
      return {
            removeGroup: (groupId) => dispatch(deleteGroup(groupId)),
            setGroupDevices: (groupId, devicesIds) => dispatch(setGroupDevices(groupId, devicesIds)),
            addToGroup: (groupId, email) => dispatch(addUserToGroup(groupId, email)),
            removeUser: (groupId, email) => dispatch(removeUserFromGroup(groupId, email)),
            addGroupRole: (groupId, role) => dispatch(addGroupRole(groupId, role)),
            removeRole: (groupId, roleId) => dispatch(removeRole(groupId, roleId))
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupSettings);
