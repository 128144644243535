import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import GoogleMapContainer from "../../../Components/GoogleMapContainer/GoogleMapContainer";
import { getDevicePosition, getDeviceTrace, resetDeviceTrace } from "../../../Store/Actions/deviceActions";
import { registerSharedTrace } from "../../../Store/Actions/sharedRouteAction";
import './Device.css';
import ShareModal from "../../../Components/ShareModal/ShareModal";

const Device = ({ isGroupDevice = false, devices, groupDevices, getDeviceCurrentPosition, getDeviceTrace, resetTrace, registerSharedTrace, sharedTraceToken }) => {
      const defaultFilter = { dateFrom: null, dateTo: null };
      const [filter, setFilter] = useState(defaultFilter);
      const { slug } = useParams();
      const device = isGroupDevice ? groupDevices[slug] : devices[slug];
      const [openShareModal, setOpenShareModal] = useState(false);

      useEffect(() => {
            let currentPositionInterval = setInterval(() => { getDeviceCurrentPosition(slug, isGroupDevice) }, 10000);

            return () => {
                  clearInterval(currentPositionInterval);
            }
      }, [slug]);

      const handleChange = (e) => {
            setFilter({ ...filter, [e.target.id]: e.target.value });
      };

      const handleSubmit = (e) => {
            e.preventDefault();
            getDeviceTrace(slug, filter, isGroupDevice);
      };

      const handleReset = (e) => {
            e.preventDefault();
            e.target.closest('form').reset()
            setFilter(defaultFilter);
            resetTrace(slug, isGroupDevice);
      };

      const handleShared = (e) => {
            e.preventDefault();
            registerSharedTrace(slug, filter);
            setOpenShareModal(true);
      };

      return (
            <>
                  {openShareModal &&
                        <ShareModal
                              isAddTrace={true}
                              setOpenShareModal={setOpenShareModal}
                              sharedTraceToken={sharedTraceToken}
                        />
                  }
                  <section className="vh-100">
                        <div className="container h-100">
                              <div className="row h-25 pb-1">
                                    <div className="col-12 h-100 overflow-auto">
                                          <form onSubmit={handleSubmit} >
                                                <h5 className='h5 my-2'>Trasa z okresu</h5>
                                                <div className="row">
                                                      <div className="col-12 col-md-3 pb-2 pb-md-0">
                                                            <div className='form-floating'>
                                                                  <input type="datetime-local"
                                                                        id="dateFrom"
                                                                        name="dateFrom"
                                                                        className="form-control form-control-lg"
                                                                        placeholder='DateRange'
                                                                        onChange={handleChange}
                                                                        max={filter.dateTo}
                                                                        min="2022-11-18T00:00"
                                                                        required />
                                                                  <label className="form-label" htmlFor='dateFrom'>Od</label>
                                                            </div>
                                                      </div>
                                                      <div className="col-12 col-md-3 pb-2 pb-md-0">
                                                            <div className='form-floating'>
                                                                  <input type="datetime-local"
                                                                        id="dateTo"
                                                                        name="dateTo"
                                                                        className="form-control form-control-lg"
                                                                        placeholder='DateRange'
                                                                        onChange={handleChange}
                                                                        min={filter.dateFrom || "2022-11-18T00:00"}
                                                                        required />
                                                                  <label className="form-label" htmlFor='dateTo'>Do</label>
                                                            </div>
                                                      </div>
                                                      <div className="col-12 col-md-2 pb-2 pb-md-0">
                                                            <button type="submit" className="btn btn-primary btn-lg btn-block h-100 btn-device">
                                                                  Filtruj
                                                            </button>
                                                      </div>
                                                      <div className="col-12 col-md-2 pb-2 pb-md-0">
                                                            <button type="button" className="btn btn-primary btn-lg btn-block h-100 btn-device" onClick={handleShared}>
                                                                  Udostępnij
                                                            </button>
                                                      </div>
                                                      <div className="col-12 col-md-2 pb-2 pb-md-0">
                                                            <button type="button" className="btn btn-primary btn-lg btn-block h-100 btn-device" onClick={handleReset}>
                                                                  Resetuj
                                                            </button>
                                                      </div>
                                                </div>
                                          </form>
                                    </div>
                              </div>
                              <div className="row d-flex align-items-center justify-content-center h-75 pt-1">
                                    <div className="col-md-12 h-100 px-0">
                                          <GoogleMapContainer
                                                containerStyle={{ width: "100%", height: "100%" }}
                                                device={device}
                                          />
                                    </div>
                              </div>
                        </div>
                  </section>
            </>
      );
};

const mapStateToProps = (state) => {
      return {
            auth: state.auth,
            devices: state.device.devices,
            groupDevices: state.device.groupDevices,
            sharedTraceToken: state.frontFeatures.sharedTraceToken
      };
};

const mapDispatchToProps = (dispatch) => {
      return {
            getDeviceCurrentPosition: (deviceToken, isGroupDevice) => dispatch(getDevicePosition(deviceToken, isGroupDevice)),
            getDeviceTrace: (deviceToken, filter, isGroupDevice) => dispatch(getDeviceTrace(deviceToken, filter, isGroupDevice)),
            resetTrace: (deviceToken, isGroupDevice) => dispatch(resetDeviceTrace(deviceToken, isGroupDevice)),
            registerSharedTrace: (deviceToken, filter) => dispatch(registerSharedTrace(deviceToken, filter))
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(Device);
