import { useEffect } from "react";
import { connect } from "react-redux";
import { getDeviceList } from "../../Store/Actions/deviceActions";
import { logout } from "../../Store/Actions/authActions";
import Nav from "./Nav";
import Logo from "../Logo/Logo";
import { getGroupsDeviceList } from "../../Store/Actions/GroupActions";

/**
 * Navbar component that displays the navigation bar on the top of the page.
 * @param {Object} props - The props object that contains devices, groupDevices, userToken, getDevices, getGroupsDevices, and logOut.
 * @param {Array} props.devices - The array of devices.
 * @param {Array} props.groupDevices - The array of group devices.
 * @param {string} props.userToken - The user token.
 * @param {Function} props.getDevices - The function that gets the device list.
 * @param {Function} props.getGroupsDevices - The function that gets the group device list.
 * @param {Function} props.logOut - The function that logs out the user.
 * @returns {JSX.Element} - The JSX element that displays the navigation bar.
 */
const Navbar = ({ devices, groupDevices, userToken, getDevices, getGroupsDevices, logOut }) => {
      useEffect(() => {
            if (userToken && !devices.length) {
                  getDevices();
            }
            if (userToken && !groupDevices.length) {
                  getGroupsDevices();
            }
      }, []);

      return (
            <div className="col-auto col-sm-4 col-md-3 col-xl-2 px-sm-2 px-0 bg-dark overflow-auto">
                  <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                        <Logo />
                        <Nav
                              deviceList={devices}
                              groupDeviceList={groupDevices}
                        />
                        <a href="/" aria-label="button" role="button" onClick={logOut} className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                              <i className="fs-4 bi bi-box-arrow-right"></i> <strong className="ms-1 d-none d-sm-inline">Wyloguj</strong>
                        </a>
                        <hr />
                  </div>
            </div>
      );
};

const mapStateToProps = (state) => {
      return {
            devices: state.device.devices,
            groupDevices: state.device.groupDevices,
            userToken: state.auth.token
      };
};

const mapDispatchToProps = (dispatch) => {
      return {
            getDevices: () => dispatch(getDeviceList()),
            getGroupsDevices: () => dispatch(getGroupsDeviceList()),
            logOut: () => dispatch(logout())
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
