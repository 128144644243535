import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { registerUserDevice } from "../../../../Store/Actions/deviceActions";

const Add = (props) => {
      const [data, setData] = useState({
            deviceToken: "",
            userToken: ""
      });

      const handleChange = (e) => {
            setData({
                  ...data,
                  [e.target.id]: e.target.value
            });
      }

      const handleSubmit = (e) => {
            e.preventDefault();
            props.registerDevice(data.deviceToken);
      }

      useEffect(() => {
            setData({
                  ...data,
                  userToken: props.auth.token
            });
      }, []);

      return (
            <section className="vh-100">
                  <div className="container py-5 h-100">
                        <div className="row d-flex align-items-center justify-content-center h-100">
                              <div className="col-md-7 col-lg-5 col-xl-5">
                                    <form onSubmit={handleSubmit}>
                                          <h3 className='h3 mb-4'>Dodaj urządzenie</h3>
                                          <div className='form-floating mb-4'>
                                                <input type="text" id="deviceToken" className="form-control form-control-lg" placeholder=' ' onChange={handleChange} required />
                                                <label className="form-label" htmlFor='deviceToken'>Numer urządzenia</label>
                                          </div>
                                          <button type="submit" className="btn btn-primary btn-lg btn-block">
                                                Dodaj <i className="bi bi-plus-circle"></i>
                                          </button>
                                    </form>
                              </div>
                        </div>
                  </div>
            </section>
      );
};

const mapStateToProps = (state) => {
      return {
            auth: state.auth
      };
};

const mapDispatchToProps = (dispatch) => {
      return {
            registerDevice: (deviceToken) => dispatch(registerUserDevice(deviceToken))
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
