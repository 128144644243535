
const initState = {
	loader: {
		active: false
	},
	sharedTraceToken: null,
	sharedDevice: {}
};

const frontFeaturesReducer = (state = initState, action) => {
	switch (action.type) {
		case 'CHANGE_LOADER_ACTIVE':
			return {
				...state,
				loader: {
					...state.loader,
					active: action.data.active
				}
			};
		case 'ADD_SHARED_TRACE_TOKEN':
			return {
				...state,
				sharedTraceToken: action.data.token
			};
		case 'SET_SHARED_DEVICE':
			return {
				...state,
				sharedDevice: action.data.device
			};
		default:
			return state;
	}
};

export default frontFeaturesReducer;
