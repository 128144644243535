import { InfoWindow, MarkerClusterer } from "@react-google-maps/api";
import { useState, useRef } from "react";

const MarkerClusterLabeled = ({ children, trace, map }) => {
      const cluster = useRef(null);
      const defaultMarkerClusterState = {
            mouseOverCluster: false,
            infoLat: 0,
            infoLng: 0,
            infoContents: []
      };
      const [markerClusterState, setMarkerClusterState] = useState(defaultMarkerClusterState);

      const handleMouseOverCluster = (e) => {
            if (!trace.length || !e.getMarkers()) {
                  return;
            }

            let contents = [];
            let traceMarkerUsed = [];
            e.getMarkers().map((marker) => {
                  trace.map(traceMarker => {
                        if (
                              marker.position.lat() === traceMarker.width &&
                              marker.position.lat() === traceMarker.width &&
                              !traceMarkerUsed.includes(traceMarker.updated) &&
                              contents.length < e.getMarkers().length
                        ) {
                              contents.push((contents.length + 1) + '. ' + traceMarker.updated);
                              traceMarkerUsed.push(traceMarker.updated);
                        }
                  });
            });

            let latPlus = ((map.zoom > 20) ? 0.0005 : 0.01) / (map.zoom * map.zoom);
            setMarkerClusterState({
                  ...markerClusterState,
                  mouseOverCluster: true,
                  infoLat: e.getCenter().lat() + ((latPlus != Infinity) ? latPlus : 0),
                  infoLng: e.getCenter().lng(),
                  infoContents: contents
            });
      };

      const handleMouseExitCluster = () => {
            setMarkerClusterState(defaultMarkerClusterState);
      };

      return (
            <>
                  {(markerClusterState.mouseOverCluster) && (
                        <InfoWindow position={{ lat: markerClusterState.infoLat, lng: markerClusterState.infoLng }}>
                              <>
                                    {markerClusterState.infoContents.map((e, i) => (
                                          <p key={i}>{e}</p>
                                    ))}
                              </>
                        </InfoWindow>
                  )}
                  <MarkerClusterer
                        onMouseOver={handleMouseOverCluster}
                        onMouseOut={handleMouseExitCluster}
                        ref={cluster}
                  >
                        {(clusterer) => children(clusterer)}
                  </MarkerClusterer>
            </>
      );
};

export default MarkerClusterLabeled;
