import authReducer from './authReducer';
import deviceReducer from './deviceReducer';
import frontFeaturesReducer from './frontFeaturesReducer';
import GroupReducer from './GroupReducer';

export default {
      auth: authReducer,
      device: deviceReducer,
      frontFeatures: frontFeaturesReducer,
      groups: GroupReducer,
};
