import { useRef, useState, useEffect } from "react";

const Toast = () => {
      const [data, setData] = useState({ delay: 2000, type: 2, text: "", show: "", zIndex: 0 });
      const toastBox = useRef(null);
      let toastTimeout;

      const getType = () => {
            switch (data.type) {
                  case 0:
                        return "alert-success";
                  case 1:
                        return "alert-warning";
                  default:
                        return "alert-info";
            }
      }

      const closeToast = (e) => {
            if (e) {
                  e.preventDefault();
            }

            if (data.show.length) {
                  setData({ ...data, show: "", zIndex: 0 });
            }
      }

      /**
       * @param {{detail: {text: string, type: number, delay: number}}} options
       */
      const showToast = ({ detail }) => {
            setData({
                  ...data,
                  delay: (typeof detail.delay == "number") ? detail.delay : data.delay,
                  type: (typeof detail.type == "number") ? detail.type : data.type,
                  text: (typeof detail.text == "string") ? detail.text : data.text,
                  show: (typeof detail.text == "string" && detail.text.length) ? "show" : "",
                  zIndex: 1070
            });
      }

      useEffect(() => {
            document.addEventListener("showToast", showToast);

            return () => {
                  document.removeEventListener("showToast", showToast);
                  clearTimeout(toastTimeout);
            }
      }, []);

      if (data.delay) {
            toastTimeout = setTimeout(closeToast, data.delay);
      }

      return (
            <div
                  ref={toastBox}
                  className={`alert ${getType()} alert-dismissible fade ${data.show} position-fixed bottom-0 end-0 w-50`}
                  style={{ zIndex: data.zIndex }}
                  role="alert"
            >
                  {data.text}
                  <button onClick={closeToast} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
      );
};

export default Toast;
