import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import Navbar from "../../Components/Navbar/Navbar";

const UserPanel = ({ devices, token }) => {
      const location = useLocation();
      if (devices && location.pathname === '/user-panel' && Object.keys(devices).length) {
            return <Navigate to={`/user-panel/device/${Object.keys(devices)[0]}`} />
      }

      if (!token) {
            return <Navigate to='/login' />
      }

      return (
            <div className='container-fluid'>
                  <div className="row flex-nowrap vh-100">
                        <Navbar />
                        <div className="col h-100 overflow-auto">
                              <Outlet />
                        </div>
                  </div>
            </div>
      );
}

const mapStateToProps = (state) => {
      return {
            devices: state.device.devices,
            token: state.auth.token
      };
};

export default connect(mapStateToProps)(UserPanel);
