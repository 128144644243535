import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import './App.css';
import Login from './Views/Login/Login';
import Register from './Views/Register/Register';
import UserPanel from './Views/UserPanel/UserPanel';
import DeviceAdd from './Views/UserPanel/Device/Add/Add';
import Device from './Views/UserPanel/Device/Device';
import Loader from './Components/Loader/Loader';
import { connect } from 'react-redux';
import Toast from './Components/Toast/Toast';
import PasswordChange from './Views/PasswordChange/PasswordChange';
import PasswordReset from './Views/PasswordChange/PasswordReset';
import SharedTrace from './Views/SharedTrace/SharedTrace';
import Group from './Views/UserPanel/Group/Group';
import Settings from './Views/UserPanel/Device/Settings/Settings';

function App({ loaderActive }) {
	return (
		<>
			<Loader open={loaderActive} />
			<Toast />
			<Switch>
				<Route path='/' exact element={<Navigate to="/login" />} />
				<Route path='/shared-trace/:slug' element={<SharedTrace />} />
				<Route path='/login' element={<Login />} />
				<Route path='/password-change' element={<PasswordChange />} />
				<Route path='/password-reset' exact element={<PasswordReset />} />
				<Route path='/password-reset/:token' element={<PasswordReset />} />
				<Route path='/register' element={<Register />} />
				<Route path='/user-panel' element={<UserPanel />} >
					<Route path='device/add' element={<DeviceAdd />} />
					<Route path='device/:slug' element={<Device />} />
					<Route path='device/:slug/settings' element={<Settings />} />
					<Route path='group/device/:slug' element={<Device isGroupDevice={true} />} />
					<Route path='group' element={<Group />} />
				</Route>
			</Switch>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		loaderActive: state.frontFeatures.loader.active
	};
};

export default connect(mapStateToProps)(App);
