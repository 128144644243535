import React, { useState } from "react";
import "./ShareModal.css";

function ShareModal({ isAddTrace, setOpenShareModal, sharedTraceToken }) {
	const copyUrl = window.location.href.split("/")[0] + "//" + window.location.href.split("/")[2] + "/shared-trace/" + ((sharedTraceToken != null) ? sharedTraceToken : ''); //url utworzony w divace
	const [writeCopy, setWriteCopy] = useState(false);

	const handelClose = () => {
		setWriteCopy(false);
		setOpenShareModal(false);
	};

	const handelCopyUrl = () => {
		navigator.clipboard.writeText(copyUrl);
		setWriteCopy(true);
	};

	return (
		<div className="share-main">
			<div className="share-container">
				<div className="share-url">
					<div className="share-img-float">
						<img className="share-img" src="/images/sledzik_sharing_min.png" />
					</div>
					<div className="share-input-float">
						{isAddTrace ?
							<input type="text" name="sharedTrase" value={copyUrl} className="share-input" /> :
							<div className="share-alert">Adres url nie został utworzony.</div>
						}
						{writeCopy && <div className="share-alert">Url został skopiowany.</div>}
					</div>
				</div>
				<div className="share-btn">
					<div className="share-btn-copy">
						{isAddTrace &&
							<button className="btn btn-primary btn-lg btn-block"
								onClick={handelCopyUrl}
							>
								Kopiuj
							</button>
						}
					</div>
					<div className="share-btn-cancel">
						<button className="btn btn-primary btn-lg btn-block"
							onClick={handelClose}
						>
							Zamknij
						</button>
					</div>
				</div>
			</div>
		</div>

	);
}

export default ShareModal;
