import { connect } from "react-redux";
import { changeDeviceSettings } from "../../../../../Store/Actions/deviceActions";
import LocatorSettings from "./LocatorSettings";
import { useState } from 'react';

const SettingsType = ({ device, changeDeviceSettings }) => {
      const [settings, setSettings] = useState({});

      if (!device) {
            return (<></>);
      }

      const Settings = () => {
            if (device) {
                  switch (device.type) {
                        case 'locator':
                        default:
                              return (<LocatorSettings settings={device.settings} />);
                  }
            }
      };

      const handleSubmit = (e) => {
            e.preventDefault();
            changeDeviceSettings(device.token, settings);
      };

      const handleChange = (e) => {
            let elements = e.currentTarget.elements;
            let obj = {};
            for (var i = 0; i < elements.length; i++) {
                  var item = elements.item(i);
                  if (item.id === "" || item.value === "") {
                        continue;
                  }

                  obj[item.id] = item.value;
            }

            console.log(obj)
            setSettings({
                  ...obj
            });
      }

      return device ? (
            <div className="container">
                  <div className="row">
                        <div className="col-12">
                              <form className="container" onSubmit={handleSubmit} onChange={handleChange}>
                                    {Settings()}
                                    <div className="row">
                                          <div className="col-12 col-sm-4">
                                                <button type="submit" className="btn btn-primary">Zapisz <i className="bi bi-save"></i></button>
                                          </div>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      ) : (<></>);
};

const mapStateToProps = (state) => {
      return {

      };
};

const mapDispatchToProps = (dispatch) => {
      return {
            changeDeviceSettings: (deviceToken, settings) => dispatch(changeDeviceSettings(deviceToken, settings))
      }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsType);