import axios from 'axios';

export const createNewGroup = (data) => {
      return async (dispatch) => {
            return await axios.post('/group/new', JSON.stringify(data))
                  .then((response) => {
                        if (response.data && response.data.success) {
                              dispatch({ type: 'ADD_OWNED_GROUP', data: { group: response.data.data } });
                        }
                  })
                  .catch(() => {
                        document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie tworzenia grupy.", type: 1 } }));
                  });
      }
};

export const getOwnedGroups = () => {
      return async (dispatch) => {
            return await axios.get('/group/owner/list')
                  .then((response) => {
                        if (response.data && response.data.success) {
                              dispatch({ type: 'SET_OWNED_GROUPS', data: { groups: response.data.data } });
                        }
                  })
                  .catch(() => {
                        document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie pozyskiwania listy posiadanych grup.", type: 1 } }));
                  });
      }
};

/**
 * Deletes group by id.
 *
 * @param {number} groupId
 * @returns void
 */
export const deleteGroup = (groupId) => {
      if (typeof groupId !== 'number' || groupId === NaN) {
            return;
      }

      return async (dispatch, getState) => {
            dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
            return await axios.delete(`/group/delete/${groupId}`)
                  .then((response) => {
                        if (response.data && response.data.success) {
                              let ownedGroups = getState().groups.ownedGroups;
                              ownedGroups = ownedGroups.filter((group) => group.id !== groupId);
                              dispatch({ type: 'SET_OWNED_GROUPS', data: { groups: ownedGroups } });
                        }

                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                  })
                  .catch(() => {
                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                        document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie usuwania grupy.", type: 1 } }));
                  })
      }
};

/**
 * Adds user to group.
 *
 * @param {number} groupId
 * @param {string} email
 * @returns void
 */
export const addUserToGroup = (groupId, email, onRegister = false) => {
      if (typeof groupId !== 'number' || groupId === NaN || typeof email !== 'string' || !email.length || typeof onRegister !== 'boolean') {
            return;
      }

      return async (dispatch, getState) => {
            dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
            return await axios.post(`/group/user/add`, JSON.stringify({ id: groupId, email }))
                  .then((response) => {
                        if (response.data && response.data.success) {
                              if (response.data.data && !onRegister) {
                                    let ownedGroups = getState().groups.ownedGroups;
                                    ownedGroups = ownedGroups.map((group) => {
                                          if (group.id === groupId) {
                                                return response.data.data;
                                          }

                                          return group;
                                    });

                                    dispatch({ type: 'SET_OWNED_GROUPS', data: { groups: ownedGroups } });
                              }

                              if (!onRegister) {
                                    document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Pomyślnie wysłano zaproszenie do grupy.", type: 0 } }));
                              }
                        }

                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                  })
                  .catch(() => {
                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                        if (!onRegister) {
                              document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie wysyłania zaproszenia do grupy.", type: 1 } }));
                        }
                  })
      }
};

/**
 * Removes user from group.
 *
 * @param {number} groupId
 * @param {string} email
 * @returns void
 */
export const removeUserFromGroup = (groupId, email) => {
      if (typeof groupId !== 'number' || groupId === NaN || typeof email !== 'string' || !email.length) {
            return;
      }

      return async (dispatch, getState) => {
            dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
            return await axios.delete(`/group/${groupId}/user/${email}/delete`)
                  .then((response) => {
                        if (response.data && response.data.success) {
                              let ownedGroups = getState().groups.ownedGroups;
                              ownedGroups = ownedGroups.map((group) => {
                                    if (group.id === groupId) {
                                          return response.data.data;
                                    }

                                    return group;
                              });

                              dispatch({ type: 'SET_OWNED_GROUPS', data: { groups: ownedGroups } });
                        }

                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                  })
                  .catch(() => {
                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                        document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie usuwania użytkownika z grupy.", type: 1 } }));
                  })
      }
};

/**
 * Sets groups devices.
 *
 * @param {number} groupId
 * @param {Array} devices
 * @returns void
 */
export const setGroupDevices = (groupId, devices) => {
      if (typeof groupId !== 'number' || groupId === NaN || !Array.isArray(devices)) {
            return;
      }

      return async (dispatch, getState) => {
            dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
            return await axios.post(`/group/devices/set`, JSON.stringify({ id: groupId, devices }))
                  .then((response) => {
                        if (response.data && response.data.success) {
                              let ownedGroups = getState().groups.ownedGroups;
                              ownedGroups = ownedGroups.map((group) => {
                                    if (group.id === groupId) {
                                          return response.data.data;
                                    }

                                    return group;
                              });

                              dispatch({ type: 'SET_OWNED_GROUPS', data: { groups: ownedGroups } });
                        }

                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                  })
                  .catch((e) => {
                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                        document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie ustawienia urządzeń grupy.", type: 1 } }));
                  })
      }
};

/**
 * Returns groups device list.
 */
export const getGroupsDeviceList = () => {
      return async (dispatch) => {
            return await axios.get(`/group/device/list`)
                  .then((response) => {
                        if (response.data && response.data.success) {
                              dispatch({ type: 'SET_GROUP_DEVICES', data: { devices: response.data.data } });
                        }
                  })
                  .catch(() => {
                        console.error('Niepowodzenie ustawiania listy urządzeń użytkownika.');
                  });
      }
}

/**
 * Adds role to group.
 *
 * @param {number} groupId
 * @param {string} name
 * @returns void
 */
export const addGroupRole = (groupId, name) => {
      if (typeof groupId !== 'number' || groupId === NaN || typeof name !== 'string' || !name.length) {
            return;
      }

      return async (dispatch, getState) => {
            dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
            return await axios.post(`/group/${groupId}/role/add`, JSON.stringify({ name }))
                  .then((response) => {
                        if (response.data && response.data.success) {
                              let ownedGroups = getState().groups.ownedGroups;
                              ownedGroups = ownedGroups.map((group) => {
                                    if (group.id === groupId && group.roles instanceof Array) {
                                          group = { ...group, roles: [...group.roles, response.data.data] };
                                    }

                                    return group;
                              });

                              dispatch({ type: 'SET_OWNED_GROUPS', data: { groups: ownedGroups } });
                        }

                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                  })
                  .catch(() => {
                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                        document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie dodawania roli do grupy.", type: 1 } }));
                  })
      }
};

/**
 * Removes role from group.
 *
 * @param {number} groupId
 * @param {number} roleId
 * @returns void
 */
export const removeRole = (groupId, roleId) => {
      if (typeof groupId !== 'number' || groupId === NaN || typeof roleId !== 'number' || roleId === NaN) {
            return;
      }

      return async (dispatch, getState) => {
            dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: true } });
            return await axios.delete(`/group/${groupId}/role/${roleId}`)
                  .then((response) => {
                        if (response.data && response.data.success) {
                              let ownedGroups = getState().groups.ownedGroups;
                              ownedGroups = ownedGroups.map((group) => {
                                    if (group.id === groupId) {
                                          return response.data.data;
                                    }

                                    return group;
                              });

                              dispatch({ type: 'SET_OWNED_GROUPS', data: { groups: ownedGroups } });
                        }

                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                  })
                  .catch(() => {
                        dispatch({ type: 'CHANGE_LOADER_ACTIVE', data: { active: false } });
                        document.dispatchEvent(new CustomEvent("showToast", { detail: { text: "Niepowodzenie usuwania roli z grupy.", type: 1 } }));
                  })
      }
};
