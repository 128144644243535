import { InfoWindow, Marker } from "@react-google-maps/api";
import { useState } from "react";
import "./MarkerLabeled.css";

const MarkerLabeled = ({ position, title, content, zIndex, clusterer, key }) => {
      const [markerState, setMarkerState] = useState({
            showInfoWindow: false
      });

      const handleMouseOver = () => {
            setMarkerState({
                  ...markerState,
                  showInfoWindow: ((title && title.length) || (content && content.length))
            });
      };

      const handleMouseExit = () => {
            setMarkerState({
                  ...markerState,
                  showInfoWindow: false
            });
      };

      return (
            <Marker
                  position={position}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseExit}
                  clusterer={clusterer}
                  zIndex={zIndex}
                  key={key}
            >
                  {markerState.showInfoWindow && (
                        <InfoWindow>
                              <>
                                    {title && title.length && (
                                          <h6>{title}</h6>
                                    )}
                                    {title && title.length && content && content.length && (
                                          <hr></hr>
                                    )}
                                    {content && content.length ? content.map((e) => (<>
                                          <p>{e}</p>
                                    </>)
                                    ) : <></>}
                              </>
                        </InfoWindow>
                  )}
            </Marker>
      )
}

export default MarkerLabeled;
